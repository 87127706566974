<template>
  <Dashboard/>
</template>

<script>
export default {
  components: {
    Dashboard: () => import("@/components/dashboard/Dashboard"),
  }
};
</script>